import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const ProtectedRoute = ({ children, allowedRoles }) => {
  const { user } = useAuth();
  const location = useLocation();

  console.log('ProtectedRoute - Current user from context:', user);
  console.log('ProtectedRoute - User role from context:', user?.role);
  console.log(
    'ProtectedRoute - User role from localStorage:',
    localStorage.getItem('userRole')
  );
  console.log('ProtectedRoute - Allowed roles:', allowedRoles);

  const userRole = user?.role || localStorage.getItem('userRole');

  if (!userRole || !allowedRoles.includes(userRole)) {
    console.log('ProtectedRoute - Access denied. Redirecting to login.');
    return <Navigate to="/login" state={{ from: location.pathname }} replace />;
  }

  console.log('ProtectedRoute - Access granted.');
  return children;
};

export default ProtectedRoute;

import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const storedRole = localStorage.getItem('userRole');
    const loginTimestamp = localStorage.getItem('loginTimestamp');

    console.log('AuthProvider useEffect - Stored user:', storedUser);
    console.log('AuthProvider useEffect - Stored role:', storedRole);

    if (storedUser && loginTimestamp) {
      const currentTime = Date.now();
      const sessionDuration = currentTime - parseInt(loginTimestamp);
      const maxSessionDuration = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

      if (sessionDuration < maxSessionDuration) {
        const parsedUser = JSON.parse(storedUser);
        console.log('AuthProvider useEffect - Parsed user:', parsedUser);
        setUser(parsedUser);
      } else {
        console.log('AuthProvider useEffect - Session expired, clearing data');
        localStorage.removeItem('user');
        localStorage.removeItem('userRole');
        localStorage.removeItem('loginTimestamp');
      }
    }
  }, []);

  const login = (userData) => {
    console.log('AuthProvider login - Received user data:', userData);
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData));
    localStorage.setItem('userRole', userData.role);
    localStorage.setItem('loginTimestamp', Date.now().toString());
    console.log(
      'AuthProvider login - User role set in localStorage:',
      userData.role
    );
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('user');
    localStorage.removeItem('userRole');
    localStorage.removeItem('loginTimestamp');
  };

  console.log('AuthProvider render - Current user:', user);

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

import React, { Suspense } from 'react';
import {
  ThemeProvider,
  createTheme,
  StyledEngineProvider,
} from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider } from './contexts/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';

// Lazy load components
const LeadCaptureForm = React.lazy(() =>
  import('./components/LeadCaptureForm')
);
const LeadTable = React.lazy(() => import('./components/LeadTable'));
const ThankYouPage = React.lazy(() => import('./components/ThankYouPage'));
const ProductManagement = React.lazy(() =>
  import('./components/ProductManagement')
);
const OrderPlacementWizard = React.lazy(() =>
  import('./components/OrderPlacementWizard')
);
const LoginScreen = React.lazy(() => import('./components/LoginScreen'));
const OrderThankYouPage = React.lazy(() =>
  import('./components/OrderThankYouPage')
);
const GeneratePDF = React.lazy(() => import('./components/GeneratePDF'));
const OrderManagement = React.lazy(() =>
  import('./components/OrderManagement')
);
// Add the new DataVisualizations component
const DataVisualizations = React.lazy(() =>
  import('./components/DataVisualizations')
);

// Create rtl cache
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

const theme = createTheme({
  direction: 'rtl',
  typography: {
    fontFamily: 'open sans, sans-serif',
  },
  palette: {
    primary: {
      main: '#abcd37',
      light: '#7FC31',
      dark: '#f7ef1c',
      contrastText: '#fff',
    },
    secondary: {
      main: '#dc004e',
      light: '#e33371',
      dark: '#9a0036',
      contrastText: '#fff',
    },
  },
});

function AppContent() {
  return (
    <Container maxWidth="lg">
      <Suspense fallback={<div>טוען...</div>}>
        <Routes>
          <Route path="/" element={<Navigate replace to="/login" />} />
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/form" element={<LeadCaptureForm />} />
          <Route
            path="/table"
            element={
              <ProtectedRoute allowedRoles={['sales_manager', 'admin']}>
                <LeadTable />
              </ProtectedRoute>
            }
          />
          <Route path="/thank-you" element={<ThankYouPage />} />
          <Route
            path="/welcome"
            element={<ThankYouPage isWelcomePage={true} />}
          />
          <Route
            path="/products"
            element={
              <ProtectedRoute allowedRoles={['admin']}>
                <ProductManagement />
              </ProtectedRoute>
            }
          />
          <Route
            path="/order"
            element={
              <ProtectedRoute
                allowedRoles={['client', 'sales_manager', 'admin']}
              >
                <OrderPlacementWizard />
              </ProtectedRoute>
            }
          />
          <Route path="/order-thank-you" element={<OrderThankYouPage />} />
          <Route
            path="/generate-pdf/:leadId/:orderId"
            element={<GeneratePDF />}
          />
          <Route
            path="/sales"
            element={
              <ProtectedRoute allowedRoles={['admin', 'sales_manager']}>
                <OrderManagement />
              </ProtectedRoute>
            }
          />
          {/* Add the new route for DataVisualizations */}
          <Route
            path="/data"
            element={
              <ProtectedRoute allowedRoles={['admin', 'sales_manager']}>
                <DataVisualizations />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Suspense>
    </Container>
  );
}

function App() {
  return (
    <HelmetProvider>
      <StyledEngineProvider injectFirst>
        <CacheProvider value={cacheRtl}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <AuthProvider>
              <Router>
                <AppContent />
              </Router>
            </AuthProvider>
          </ThemeProvider>
        </CacheProvider>
      </StyledEngineProvider>
    </HelmetProvider>
  );
}

export default App;
